@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--vh: 0.01;
}
body {
	margin: 0;
	font-family: Meticula, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@layer utilities {
	/* Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
	::-webkit-scrollbar {
		display: none;
	}
	.no-scrollbar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}

@layer components {
	.home-button-icon {
		@apply w-28 
		h-28 
		shadow-md 
		border-[2px] 
		p-6
		flex 
		flex-col 
		rounded-3xl 
		items-center 
		text-center
		bg-white;
	}
	.home-button {
		@apply w-32 
		p-3 
		flex 
		flex-col 
		rounded-md 
		items-center 
		text-center 
		space-y-2;
	}

	.entity-button-profile {
		@apply flex 
		w-full 
		bg-white
		flex-row 
		cursor-pointer 
		place-items-center 
		rounded-md 
		border-[1px] 
		border-slate-300 
		px-2 
		py-3 
		shadow-sm 
		shadow-slate-400 
		md:w-96;
	}

	.profile-info-responsive-data {
		@apply flex 
		flex-col 
		text-center 
		/* border-b-2 
		pb-3  */
		md:flex-row 
		md:gap-y-1 
		md:gap-x-1.5 
		md:w-full 
		md:justify-center;
	}
	.input-style {
		@apply w-full 
		bg-white
    h-10 
    p-2 
    rounded-md 
    border-[1px] 
    border-gray-300 
    text-gray-900 
    placeholder-transparent 
    focus:outline-none 
    focus:border-sky-500 
    focus:ring-1 
    hover:border-sky-500 
    hover:ring-sky-500 
    hover:ring-[1px];
	}

	.input-style-note {
		@apply w-full 
		bg-white
    p-2 
    rounded-md 
    border-[1px] 
    border-gray-300 
    text-gray-900 
    placeholder-transparent 
    focus:outline-none 
    focus:border-sky-500 
    focus:ring-1 
    hover:border-sky-500 
    hover:ring-sky-500 
    hover:ring-[1px];
	}
	.input-style-label {
		@apply px-2 
    left-2 
    -top-2.5
    absolute 
    bg-white 
    text-gray-600 
    text-sm 
    transition-all 
    peer-placeholder-shown:text-base 
    peer-placeholder-shown:text-gray-400 
    peer-placeholder-shown:top-2
    peer-focus:-top-2.5
    peer-focus:text-gray-600 
    peer-focus:text-sm;
	}

	.format-div-photo {
		@apply rounded-md 
		border-[3px] 
		bg-white 
		px-1 
		py-4 
		shadow-sm 
		hover:border-sky-500 
		hover:ring-[1px] 
		hover:ring-sky-500;
	}

	.modern-h-screen {
		/* Fallback for browsers that do not support Custom Properties */
		/* min-height: 100vh; */
		height: calc(1vh * 100);
	}

	.specialDay {
		background-color: #fec;
	}

	.tooltip {
		@apply invisible 
		absolute;
	}

	.has-tooltip:hover .tooltip {
		@apply visible 
		z-50;
	}
}
